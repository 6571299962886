<template>
    <div class="electronic-document-signing">
        <h1 class="text-secondary font">Elektronsko podpisovanje listine</h1>
        <h3 class="mt-2 font">Koroška cesta 33, Podpisovanje listine za spremembo cene vzdrževanja</h3>
        <b-embed class="mt-2" type="iframe" aspect="16by9" src="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf#toolbar=0"/>
        <div class="mt-4">
            <h1 class="text-secondary font">Elektronsko glasovanje</h1>
            <h3 class="mt-1">Etažni lastniki, ki soglašate z zgoraj predlaganim sklepom, v spodnjem obrazcu, označite <b-link>“ZA”</b-link>,
                V kolikor nasprotujete predlaganemu sklepu, označite <b-link>“PROTI”</b-link>. Za elektronsko glasovanje potrebujete
                ustrezno kvalificirano
                <b-link href="#">digitalno potrdilo</b-link>.</h3>
        </div>
        <div class="mt-2" style="background-color: #009FD4;">
            <b-row class="px-3 py-2" align-v="center">
                <b-col cols="12" md="8">
                    <label class="check">
                        <input type="radio" :value="true" v-model="vote" name="type"/>
                        <span class="mark"></span>
                        <h3 class="d-inline mb-0 ml-2 text-white font">Strinjam se s predlagam sklepom</h3>
                    </label>
                    <label class="check mt-1">
                        <input type="radio" :value="false" v-model="vote" name="type"/>
                        <span class="mark"></span>
                        <h3 class="d-inline mb-0 ml-2 text-white font">Nasprotujem predlaganemu sklepu</h3>
                    </label>
                    <small v-if="vote === null" class="text-white bg-danger">Izberite eno polje</small>
                </b-col>
                <b-col cols="12" md="4" class="d-flex justify-content-start justify-content-md-end mt-1 mt-md-0">
                    <b-button squared variant="primary" class="font" size="lg" @click="validation">Potrdi izbiro</b-button>
                </b-col>
            </b-row>
        </div>
        <div class="mt-2" style="background-color: #E5E6E5;">
            <h2 class="pt-2 text-center font">Trenutni rezultati glasovanja po solastniških deležih</h2>
            <b-row class="p-4">
                <b-col cols="12" md="6">
                    <chart :options="chartOptions" :series="series"/>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card-text class="mb-0 font font-medium-2">ZA</b-card-text>
                    <b-progress>
                        <b-progress-bar value="25" label="25 %" :style="{ 'background-color': colors[0] }"/>
                    </b-progress>
                </b-col>
            </b-row>
        </div>
        <p>Pravila in pogoje elektronskega glasovanja, najdete
            <b-link>tukaj</b-link>.</p>
        <div class="d-flex justify-content-center justify-content-md-end">
            <b-button squared variant="primary" size="sm">
                <h2 class="mb-0 text-white">Prenesi dokument <fa icon="download"/></h2>
            </b-button>
        </div>
    </div>
</template>

<script>
    import chart from '../../Components/Chart'
    import {BEmbed, BLink, BRow, BCol, BButton, BCardText, BProgress, BProgressBar} from 'bootstrap-vue'
    export default {
        components: {
            chart,
            BEmbed,
            BLink,
            BRow,
            BCol,
            BButton,
            BCardText,
            BProgress,
            BProgressBar
        },
        data() {
            return {
                vote: null,
                series: [30, 40],
                colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#3F51B5', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B', '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7'],
                chartOptions: {
                    labels: ['ZA', 'PROTI'],
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '95%',
                                background: '#fff',
                                labels: {
                                    show: true,
                                    total: {
                                        show: true,
                                        showAlways: true,
                                        label: 'Skupaj',
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        formatter(w) {
                                            return `${w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b
                                            }, 0)  } %`
                                        }
                                    },
                                    value: {
                                        show: true,
                                        label: '38,96',
                                        fontSize: '50px',
                                        offsetY: 20
                                    }
                                }
                            }
                        }
                    }
                }

            }
        },
        methods: {
            validation() {
                if (this.vote !== null) {
                    alert('ok')
                }
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
.electronic-document-signing .font {
  font-weight: bold;
}
.electronic-document-signing a {
  color: #009FD4;
}
.electronic-document-signing label {
  margin: 0;
}
.check {
  display: block;
  position: relative;
  cursor: pointer;
}
.check input {
  opacity: 0;
}
.mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border: 1px solid #626363;
  background-color: #fff;
}
.mark:after {
  content: "";
  position: absolute;
  display: none;
}
.check input:checked ~ .mark:after {
  display: block;
}
.check .mark:after {
  left: 2px;
  top: 2px;
  width: 22px;
  height: 22px;
  background:
      linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 47%,black 45%,black 53%,rgba(0,0,0,0) 47%,rgba(0,0,0,0) 100%),
      linear-gradient(135deg, #fff 0%,#fff 47%,black 45%,black 53%,#fff 47%,#fff 100%);
}
.electronic-document-signing .progress {
  height: 2.5rem !important;
}
.electronic-document-signing .progress div {
  font-weight: bold;
  color: black;
  font-size: 20px;
}
</style>
